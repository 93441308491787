
import Hero from '../sections/Hero.vue'
export default {
  name: 'ErrorMessage',

  components: { Hero },

  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 404,
      }),
    },
  },
}
